import * as Sentry from '@sentry/sveltekit';
import { handleErrorWithSentry, Replay } from '@sentry/sveltekit';

Sentry.init({
	dsn:
		import.meta.env.MODE === 'production'
			? 'https://2c4c753e0642e6681236a5a962ee8186@o237452.ingest.sentry.io/4506582566961152'
			: undefined,
	tracesSampleRate: 1.0,
	environment: import.meta.env.MODE,

	// This sets the sample rate to be 10%. You may want this to be 100% while
	// in development and sample at a lower rate in production
	replaysSessionSampleRate: 0.1,

	// If the entire session is not sampled, use the below sample rate to sample
	// sessions when an error occurs.
	replaysOnErrorSampleRate: 1.0,

	// If you don't want to use Session Replay, just remove the line below:
	integrations: [new Replay()]
});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
